<template>
	<div class="article">
		<Header></Header>

		<main v-if="article">
			<h1>{{ article.title }}</h1>
			<p class="author">By {{ article.author }} &middot; {{ article.length }} minute read</p>
			<img v-if="!article.inline_video" :src="article.image">
			<MarkdownBlock class="markdown"
				:text="article.markdown">
			</MarkdownBlock>
			<br>
			<Disclaimer :content="article.disclaimer || defaultDisclaimer"></Disclaimer>
<!--			<ExtendDownload :type="'article'"></ExtendDownload>-->
			<hr>
			<Copyright></Copyright>
		</main>
		<template v-else>
			<Loading v-if="$route.query.account === 'doctorplan'" text="Loading article..."></Loading>
			<GenericLoading v-else text="Loading article..."></GenericLoading>
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Loading from '@/component/Loading'
import GenericLoading from '@/component/GenericLoading'
import Header from './Header'
import MarkdownBlock from './markdown/Block'
import Disclaimer from './Disclaimer'
import Copyright from '../home/copyright/Copyright'
import ExtendDownload from '@/component/ExtendDownload'

export default {
	name: 'article-viewer',

	props: {
		id: {
			type: String,
			required: true
		},
		query: {
			type: Object
		}
	},

	data() {
		return {
			missing: false,
			defaultDisclaimer: 'All information on DoctorPlan is for educational pursuit and information purposes only. It is not intended nor implied to be a substitute for professional medical advice. The viewer should always consult his or her healthcare provider to determine the appropriateness of the information for their own situation or if they have any questions regarding their medical condition, diagnosis, procedures, treatment plan, or other health related topics.'
		}
	},

	mounted() {
		if (!this.article) {
			this.handoff(this.query)
				.then(() => {
					this.loadArticle().then(() => {
						if (!this.article) this.missing = true
					})
					.catch((err) => {
						this.$message({
							showClose: true,
							message: err.code,
							type: 'error',
							duration: '0'
						})
					})
				})

		}
	},

	methods: {
		...mapActions('auth', [
			'handoff'
		]),
		...mapActions('content', [
			'loadObject'
		]),
		loadArticle() {
			return this.loadObject({ type: 'article', id: this.id })
		}
	},

	computed: {
		...mapGetters('content', [
			'getObject'
		]),
		article() {
			return this.getObject('article', this.id)
		}
	},

	components: {
		MarkdownBlock,
		Header,
		Loading,
		Disclaimer,
		Copyright,
		ExtendDownload,
		GenericLoading
	}
}
</script>

<style lang="scss" scoped>
@import "style/header.scss";
@import "style/viewer.scss";
</style>
